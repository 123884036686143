import { instance } from '@/utils/request'

// 个人信息
export function getUserSysUser () {
  return instance({
    method: 'post',
    url:'/v1/sysUser/user'
  })
}

// 发送短信
export function getSmsSend (data) {
  return instance({
    method: 'post',
    url:'/v1/sysSmsHistory/getSmsSend',
    data,
  })
}
// 修改手机号
export function update_phone (data) {
  return instance({
    method: 'post',
    url:'/v1/sysUser/update_phone',
    data,
  })
}
// 修改密码
export function update_pwd (data) {
  return instance({
    method: 'post',
    url:'/v1/sysUser/update_pwd',
    data,
  })
}
// 修改支付密码
export function update_pay (data) {
  return instance({
    method: 'post',
    url:'/v1/sysUser/update_pay',
    data,
  })
}