<template>
  <div class="personCenter_box">
    <!-- 基本信息 -->
    <div class="personCenter_info">
        <div>
          <div style="display: flex;">
             <i style="color:#4070f9;font-size:25px;margin-top:5px" class="el-icon-setting" ></i>
             <p class="personCenter_text">基本设置</p>
          </div>
          <p class="personCenter_line"></p>
        </div>
        <h4 style="margin-top:27px;color:#000">基本信息</h4>
        <div class="personCenter_account">
          <div style="text-align: right;">
            <p> <span style="color:#6591F1 ">*</span> 用户信息：</p>
            <p><span style="color:#6591F1 ">*</span>用户姓名：</p>
            <p><span style="color:#6591F1 ">*</span>角色：</p>
          </div>
          <div>
              <p>{{information.username}}</p>
              <p>{{information.name}}</p>
              <el-tag size="small">{{information.roleName}}</el-tag>
          </div>
          
        </div>
    </div>
    <!-- 安全设置 -->
    <div class="personCenter_change">
      <h4 style="margin-top:27px;color:#000">安全设置</h4>
      <!-- 修改手机号 -->
      <div>
         <div class="personCenter_flex">
          <div  class="personCenter_password">
             <i class="el-icon-circle-check" style="color:#4070f9;font-size:17px;margin-top:5px"></i>
             <p class="personCenter_passwordText">登录账号:{{information.username}}</p>
          </div>
          <p class="circle" v-if="!showIhone" @click="changeIhone">修改</p>
        </div>
          <el-form v-if="showIhone" ref="formIhone" :model="formIhone" :rules="rules" label-width="100px">
          <el-form-item label="登录密码:" prop="password">
            <el-input v-model.trim="formIhone.password" type="password" style="width:360px" size="small"></el-input>
          </el-form-item>
           <el-form-item label="新手机号:" prop="phone">
            <el-input v-model.trim="formIhone.phone"  style="width:360px" size="small"></el-input>
          </el-form-item>
           <el-form-item label="验证码:" prop="verification">
            <el-input v-model.trim="formIhone.verification" style="width:208px" size="small"></el-input>
            <el-button class="button" type="primary" size="small"  v-if="!isTimerSropPhone" @click="getPhoneCode">获取验证码</el-button>
            <el-button style="width:148px;margin-left:5px;" size="small"  disabled v-else>{{msgCodeBtnTextPhone}}</el-button>
          </el-form-item>
          <el-form-item >
           <el-button class="el-button-change" type="primary" size="small" :loading="loadingPhone" @click="changePhone">修改</el-button>
           <el-button  size="small" @click="cancelIhone">取消</el-button>
          </el-form-item>
        </el-form>
        <p class="personCenter_line1"></p>
      </div> 
      <!-- 修改密码 -->
      <div >
        <div class="personCenter_flex">
          <div  class="personCenter_password">
             <i class="el-icon-circle-check" style="color:#4070f9;font-size:17px;margin-top:5px"></i>
             <p class="personCenter_passwordText">账户密码</p>
          </div>
          <p class="circle" v-if="!showPassword" @click="changePassword">修改</p>
        </div>
        <el-form v-if="showPassword" ref="form" :model="form" :rules="rulesPwd" label-width="100px">
          <el-form-item label="原密码:" prop="oldPwd">
            <el-input v-model.trim="form.oldPwd" type="password" style="width:360px" size="small"></el-input>
          </el-form-item>
           <el-form-item label="输入新密码:" prop="newPwd">
            <el-input v-model.trim="form.newPwd" type="password" style="width:360px" size="small"></el-input>
          </el-form-item>
           <el-form-item label="确认新密码:" prop="relNewPwd">
            <el-input v-model.trim="form.relNewPwd" type="password" style="width:360px" size="small"></el-input>
          </el-form-item>
           <el-form-item label="验证码:" prop="code">
            <el-input v-model.trim="form.code" style="width:208px"  size="small"></el-input>
            <el-button class="button" type="primary" size="small" v-if="!isTimerSropCode" @click="getNewPwd">获取验证码</el-button>
            <el-button style="width:148px;margin-left:5px;" size="small"  disabled v-else>{{msgCodeBtnTextCode}}</el-button>
          </el-form-item>
          <el-form-item >
           <el-button class="el-button-change" type="primary" size="small" :loading="loadingPwd" @click="changeNewPwd">修改</el-button>
           <el-button  size="small" @click="cancelPassword">取消</el-button>
          </el-form-item>
        </el-form>
        <p class="personCenter_line1"></p>

      </div>

      <!-- 支付密码修改 -->
       <div v-if="employee == 'true'">
        <div class="personCenter_flex">
          <div  class="personCenter_password">
             <i class="el-icon-circle-check" style="color:#4070f9;font-size:17px;margin-top:5px"></i>
             <p class="personCenter_passwordText">支付密码</p>
          </div>
          <p class="circle" v-if="!showPayment" @click="changePayment">修改</p>
        </div>
        <el-form v-if="showPayment" ref="formPayment" :rules="rulesPayment" :model="formPayment" label-width="100px">
          <el-form-item label="原密码:" prop="oldPwd">
            <el-input v-model="formPayment.oldPwd" type="password" style="width:360px" size="small"></el-input>
          </el-form-item>
           <el-form-item label="输入新密码:" prop="newPwd">
            <el-input v-model="formPayment.newPwd" type="password" style="width:360px" size="small"></el-input>
          </el-form-item>
           <el-form-item label="确认新密码:" prop="relNewPwd">
            <el-input v-model="formPayment.relNewPwd" type="password" style="width:360px" size="small"></el-input>
          </el-form-item>
           <el-form-item label="验证码:" prop="code">
            <el-input v-model="formPayment.code" style="width:208px"  size="small"></el-input>
            <el-button class="button" type="primary" size="small" v-if="!isTimerSropPayCode" @click="getPaymentPwd">获取验证码</el-button>
            <el-button style="width:148px;margin-left:5px;" size="small"  disabled v-else>{{msgCodeBtnTextPayCode}}</el-button>
          </el-form-item>
          <el-form-item >
           <el-button class="el-button-change" type="primary" size="small" :loading="loadingPayment" @click="changePaymentCode">修改</el-button>
           <el-button  size="small" @click="cancelPayment">取消</el-button>
          </el-form-item>
        </el-form>
        <p class="personCenter_line1"></p>

      </div>
    </div>
  </div>
</template>

<script>
import { getUserSysUser,getSmsSend,update_phone,update_pwd,update_pay } from '@/api/person.js'
// import { instance } from "@/utils/request";
export default {
  data() {
    return {
      information:{},
      formPayment:{
        oldPwd:'',
        newPwd:'',
        relNewPwd:'',
        code:'',
      },
      loadingPayment:false,
      rulesPayment:{
        oldPwd:[
           { required: true,message: '请输入原密码', trigger: ['blur', 'change'] },
           { min: 6,max: 18,message: '密码长度6-18位', trigger: ['blur', 'change'] },
           { pattern: /^[a-zA-Z0-9]{6,18}$/,message: '密码仅支持数字或者字母',trigger: ['blur', 'change'] }
        ],
        newPwd:[
          { required: true,message: '请输入新密码', trigger: ['blur', 'change'] },
          { min: 6,max: 18,message: '密码长度6-18位', trigger: ['blur', 'change'] },
          { pattern: /^[a-zA-Z0-9]{6,18}$/,message: '密码仅支持数字或者字母',trigger: ['blur', 'change'] }
        ],
        relNewPwd:[
          { required: true,message: '请确认新密码', trigger: ['blur', 'change'] },
          { min: 6,max: 18,message: '密码长度6-18位', trigger: ['blur', 'change'] },
          { pattern: /^[a-zA-Z0-9]{6,18}$/,message: '密码仅支持数字或者字母',trigger: ['blur', 'change'] }
        ],
        code:[
           { required: true, message: '请输入您验证码', trigger: ['blur', 'change']},
           { max: 7,message: '验证码长度不超过6位',trigger: ['blur', 'change']}
        ]
      },
      formIhone:{
        password:'',
        phone:'',
        verification:''
      },

      isTimerSropPhone:false,
      msgCodeBtnTextPhone:'',
      time: 60,
      timer: '',
      times: '',//修改手机号
      isTimerSropCode:false,
      msgCodeBtnTextCode:'',
      timeCode: 60,
      timerCode: '',
      timesCode: '',//修改密码
      isTimerSropPayCode:false,
      msgCodeBtnTextPayCode:'',
      timePayCode: 60,
      timerPayCode: '',
      timesPayCode: '',//修改支付密码
      loadingPhone:false,
      rules:{
        password:[
           { required: true,message: '请输入登录密码', trigger: ['blur', 'change'] },
           { min: 6,max: 18,message: '密码长度6-18位', trigger: ['blur', 'change'] },
           { pattern: /^[a-zA-Z0-9]{6,18}$/,message: '密码仅支持数字或者字母',trigger: ['blur', 'change'] }
        ],
        phone:[
           { required: true,message: '请输入新手机号', trigger: ['blur', 'change'] },
           { max: 12, message: '手机号长度最多不能超过11位', trigger: ['blur', 'change']},
           { pattern: /^1((34[0-8])|(8\d{2})|(([35][0-35-9]|4[579]|66|7[0-8]|9[0-9])\d{1}))\d{7}$/, message: '请输入正确的手机号', trigger: ['blur', 'change']}
        ],
        verification:[
           { required: true, message: '请输入您验证码', trigger: ['blur', 'change']},
           { max: 7,message: '验证码长度不超过6位',trigger: ['blur', 'change']}
        ]

      },
      form:{    //修改密码
       oldPwd:'',
       newPwd:'',
       relNewPwd:'',
       code:'',
      },
      loadingPwd:false,
      rulesPwd:{
        oldPwd:[
          { required: true,message: '请输入原密码', trigger: ['blur', 'change'] },
          { min: 6,max: 18,message: '密码长度6-18位', trigger: ['blur', 'change'] },
          { pattern: /^[a-zA-Z0-9]{6,18}$/,message: '密码仅支持数字或者字母',trigger: ['blur', 'change'] }
        ],
        newPwd:[
          { required: true,message: '请输入新密码', trigger: ['blur', 'change'] },
          { min: 6,max: 18,message: '密码长度6-18位', trigger: ['blur', 'change'] },
          { pattern: /^[a-zA-Z0-9]{6,18}$/,message: '密码仅支持数字或者字母',trigger: ['blur', 'change'] }
        ],
        relNewPwd:[
          { required: true,message: '请确认新密码', trigger: ['blur', 'change'] },
          { min: 6,max: 18,message: '密码长度6-18位', trigger: ['blur', 'change'] },
          { pattern: /^[a-zA-Z0-9]{6,18}$/,message: '密码仅支持数字或者字母',trigger: ['blur', 'change'] }
        ],
        code:[
           { required: true, message: '请输入您验证码', trigger: ['blur', 'change']},
           { max: 7,message: '验证码长度不超过6位',trigger: ['blur', 'change']}
        ]

      },
      showPassword:false,
      showIhone:false,
      showPayment:false,
      employee:false
    }
  },
  mounted () {
    this.getPeisonList()
   
  },
  created () {
     this.employee = localStorage.getItem('employee')
    console.log(this.employee,'this.employee');
  },
  methods:{
    //获取个人信息
   async  getPeisonList() {
      const res = await getUserSysUser()
      if( res.success ) {
         this.information = res?.data
      }
    },
    //获取修改手机号码验证码按钮
   async getPhoneCode () {
      if (/^1((34[0-8])|(8\d{2})|(([35][0-35-9]|4[579]|66|7[0-8]|9[0-9])\d{1}))\d{7}$/.test(this.formIhone.phone)) {
        if (this.formIhone.phone === this.information.username ) {
              this.$message.error('当前新手机号不能和登录账号一致')
              return false
        }
        await getSmsSend({
          code:this.formIhone.verification,
          phone:this.formIhone.phone,
        })
        this.timeBuild()
        this.$message.success('发送成功')
      }
      
    },
     // 修改手机号读秒倒计时
    timeBuild () {
      this.times = new Date().getTime()
      localStorage.setItem('times', this.times)
      this.timer = setInterval(() => {
        if (this.time < 0) {
          this.time = 60
          this.isTimerSropPhone = false
          clearInterval(this.timer)
        } else {
          this.isTimerSropPhone = true
          this.msgCodeBtnTextPhone = this.time-- + 's'
        }
      }, 1000)
    },
    // 密码读秒倒计时
    timeBuildCode () {
      this.timesCode = new Date().getTime()
      localStorage.setItem('times', this.timesCode)
      this.timerCode = setInterval(() => {
        if (this.timeCode < 0) {
          this.timeCode = 60
          this.isTimerSropCode = false
          clearInterval(this.timerCode)
        } else {
          this.isTimerSropCode = true
          this.msgCodeBtnTextCode = this.timeCode-- + 's'
        }
      }, 1000)
    },
     // 支付密码读秒倒计时
    timeBuildPayCode () {
      this.timesPayCode = new Date().getTime()
      localStorage.setItem('times', this.timesPayCode)
      this.timerPayCode = setInterval(() => {
        if (this.timePayCode < 0) {
          this.timePayCode = 60
          this.isTimerSropPayCode = false
          clearInterval(this.timerPayCode)
        } else {
          this.isTimerSropPayCode = true
          this.msgCodeBtnTextPayCode = this.timePayCode-- + 's'
        }
      }, 1000)
    },

    //修改手机号
    changePhone () {
       this.$refs.formIhone.validate(async (valid) => {
          if (valid) {
             this.loadingPhone = true
              setTimeout(() => {
                  this.loadingPhone = false;
                }, 10000);
             const res = await update_phone({
               code:this.formIhone.verification,
               newPhone:this.formIhone.phone,
               pwd:this.formIhone.password,
             })
             if ( res.success ) {
                // this.loadingPhone = false
                // this.showIhone = false
                // this.$message.success('修改手机号码成功')
                // this.getPeisonList()
                // this.formIhone = {
                //   password: '',
                //   phone: '',
                //   verification: '',
                // }
                // this.$nextTick(() => {
                //     this.$refs.formIhone.resetFields();
                // })
                // clearInterval(this.timer)
                // this.isTimerSropPhone = false
                this.$message.success('修改成功，请重新登录')
               this.$router.push({name:'login'})
             } else {
               this.$message.error(res.message)
               this.loadingPhone = false
             }
          }
       })
    },

    //修改手机号文字按钮
    changeIhone () {
      this.showIhone = true 
    },
    //取消手机号文字按钮
    cancelIhone () {
      this.showIhone = false
      this.formIhone = {
        password: '',
        phone: '',
        verification: '',
      }
      this.$nextTick(() => {
        if (this.$refs.formIhone) {
             this.$refs.formIhone.resetFields();
        }
       
      })
       clearInterval(this.timer)
       this.isTimerSropPhone = false

    },

    //修改密码获取手机号按钮
   async getNewPwd () {
      if (/^1((34[0-8])|(8\d{2})|(([35][0-35-9]|4[579]|66|7[0-8]|9[0-9])\d{1}))\d{7}$/.test(this.information.username)) {
        console.log(this.form.newPwd,'this.form.newPwd');
          if( !this.form.newPwd || !this.form.relNewPwd || !this.form.oldPwd) {
            this.$message.error('请先输入密码')
            return false
          }
          if ( this.form.newPwd === this.form.relNewPwd ) {
             const res = await getSmsSend({
              code:this.form.code,
              phone:this.information.username,
            })
            if ( res.success ) {
                 this.timeBuildCode ()
                 this.$message.success('发送成功')
            }else {
                //  this.$message.error(res.message)
            }
          } else {
             this.$message.error('两次输入的密码不一致')
          }
      }
    },

    //修改密码按钮
    changeNewPwd () {
       this.$refs.form.validate( async (valid) =>{
         if(valid) {
            this.loadingPwd = true
            setTimeout(() => {
                 this.loadingPwd = false;
            }, 10000);
            if ( this.form.newPwd !== this.form.relNewPwd) {
                this.$message.error('两次输入的密码不一致')
                return false
            }
            const res = await update_pwd({
                code: this.form.code,
                newPwd: this.form.newPwd,
                oldPwd: this.form.oldPwd,
                phone: this.information.username
            })
            if ( res.success ) {
               this.$message.success('修改成功，请重新登录')
               this.$router.push({name:'login'})
            }else {
              this.loadingPwd = false
            }
         }
       })
    },

    //修改账户密码文字按钮
    changePassword () {
      this.showPassword = true
      this.form = {
        oldPwd:'',
       newPwd:'',
       relNewPwd:'',
       code:'',
      }
      this.$nextTick(() => {
        if (this.$refs.form) {
            this.$refs.form.resetFields();
          }
      })
       clearInterval(this.timerCode)
       this.isTimerSropCode = false
    },
    //取消账户密码文字按钮
    cancelPassword () {
       this.showPassword = false
    },
    //修改支付密码获取验证码按钮
   async getPaymentPwd () {
       if (/^1((34[0-8])|(8\d{2})|(([35][0-35-9]|4[579]|66|7[0-8]|9[0-9])\d{1}))\d{7}$/.test(this.information.username)) {
          if( !this.formPayment.newPwd || !this.formPayment.relNewPwd || !this.formPayment.oldPwd) {
              this.$message.error('请先输入密码')
              return false
            }
          if ( this.formPayment.newPwd === this.formPayment.relNewPwd ) {
             const res = await getSmsSend({
              code:this.formPayment.code,
              phone:this.information.username,
            })
            if ( res.success ) {
                this.timeBuildPayCode()
                this.$message.success('发送成功')
            }else {
                // this.$message.error(res.message)
            }
          } else {
             this.$message.error('两次输入的密码不一致')
          }
      }
    },

    //修改支付密码按钮
    changePaymentCode () {
        this.$refs.formPayment.validate(async ( valid ) =>{
          if( valid ) {
             this.loadingPayment = true
             setTimeout(() => {
                this.loadingPayment = false;
              }, 10000);
            if ( this.formPayment.newPwd !== this.formPayment.relNewPwd) {
                this.$message.error('两次输入的密码不一致')
                return false
            }
             const res = await update_pay({
                code: this.formPayment.code,
                newPwd: this.formPayment.newPwd,
                oldPwd: this.formPayment.oldPwd,
                phone: this.information.username
            })
            if( res.success ) {
               this.loadingPayment = false
               this.showPayment = false
               this.$message.success('修改支付密码成功')
                this.formPayment = {
                    oldPwd:'',
                    newPwd:'',
                    relNewPwd:'',
                    code:''
                }
                this.$nextTick(() => {
                  if( this.$refs.formPayment) {
                      this.$refs.formPayment.resetFields();
                    }
                })
                clearInterval(this.timer)
                this.isTimerSropPayCode = false
            } else {
              this.loadingPayment = false;
            }
          }
        })
    },

    //修改支付密码文字按钮
    changePayment () {
      this.showPayment = true
    },
    //取消支付密码文字按钮
    cancelPayment() {
      this.showPayment = false
      this.formPayment = {
          oldPwd:'',
          newPwd:'',
          relNewPwd:'',
          code:'',
      }
      this.$nextTick(() => {
        if (this.$refs.formPayment) {
             this.$refs.formPayment.resetFields();
        }
      })
       clearInterval(this.timerPayCode)
       this.isTimerSropPayCode = false

    },

  }
}
</script>

<style  lang="less" scoped>
.personCenter_box {
  width: 100%;
  // height: 100%;
  
  .personCenter_info {
    width: 100%;
    height: 291px;
    background-color: #fff;
    padding: 20px;
    .personCenter_text {
      width: 64px;
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 700;
      color: #333333;
      line-height: 18px;
      margin-left: 10px;
      margin-top: 10px;
    }
    .personCenter_line {
      border-bottom:1px solid #ECECEC ;
      margin-top: 20px;
    }
    .personCenter_account {
      // width: 400PX;
      display: flex;
      margin-left: 80px;
      line-height: 50px;
      
      
  }
  }
  .personCenter_change {
    width: 100%;
    // height: 100%;
    background-color: #fff;
    margin-top: 20px;
    padding: 20px;
    padding-bottom: 20px;
    margin-bottom: 40px;
    .personCenter_flex {
      width: 500px;
      display: flex;
      justify-content: space-between;
      .circle {
        width: 30px;
        height: 30px;
        margin-top: 30px;
        text-align: center;
        color: #6591F1;
        font-size: 14px;
        cursor: pointer
      }
    }
    .personCenter_password {
      display: flex;
      margin-left: 80px;
      margin-top: 30px;
      .personCenter_passwordText {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 30px;
        margin-left: 10px;
      }
    }
    .el-form {
      margin-left: 40px;
      margin-top: 20px;
      /deep/ .el-input__inner {
        background: #f7f8fa;
        border: none;
      }
      .button {
        width: 148px;
        margin-left: 5px;
        background-color: #6591F1 ;
      }
      .el-button-change {
        background-color: #6591F1 ;
      }
     
    }
    .personCenter_line1 {
      width: 600px;
      border-bottom:1px solid #ECECEC ;
      margin-left: 40px;
      margin-top: 20px;

    }
  }
}
</style>